import React, { useState } from "react";
import { Link } from "gatsby";
import "./float.css";


const Float = () => {
    
    const [visible, setVisible] = useState(false);

    const handleClick = () => {
        setVisible(!visible);
    }

    const renderMenu = () => (
        <div className="float-menu">
            <Link to="/">hiwijaya_</Link>
            <Link to="/blog">/blog</Link>
            <Link to="/about">/about</Link>
        </div>
    );

    return(
        <div className="float-wrap">
            <div className="float" onClick={handleClick} role="button">
                <div className="bar1"></div>
                <div className="bar2"></div>
            </div>
            {visible && renderMenu()}
        </div>
    );
};
export default Float;