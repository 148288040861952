import React from "react";
import "./tag.css";
import { Link } from "gatsby";


const Tag = ({title}) => (
    <Link to={`/tags/${title}`}>
        <span className="tag">{`#${title}`}</span>
    </Link>
)
export default Tag;