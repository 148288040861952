import React from "react";
import { Link } from "gatsby";
import "./post.css";
import Tag from "./tag";


const Post = ({title, date, desc, tags, author, slug}) => (
    <div className="post-item">
        <div className="date">{date}</div>
        <div className="title">
            <Link to={`/blog${slug}`}>
                <h3>{title}</h3>
            </Link>
            <span>
            {
                tags.map(tag => ( <Tag key={tag} title={tag}/> ))
            }
            </span>
        </div>
    </div>
)
export default Post;