import React from "react";
import { Link } from "gatsby";
import "./sidebar.css";


const Sidebar = () => (
    <div className="sidebar">
        <div className="title">
            <Link to="/">
                I'm <br/>
                Happy <br/>
                Indra <br/>
                Wijaya_
            </Link>
            <span>a software engineer</span>
        </div>
        <div className="menu">
            <Link to="/blog">/blog</Link>
            <Link to="/about">/about</Link>
        </div>
    </div>
);
export default Sidebar;