/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import './layout.css';
import Sidebar from "./sidebar";
import Footer from "./footer";
import Float from "./float";


const Layout = ({ children }) => (
    <div className="layout">
        <Sidebar />
        <Float />
        <main>
            {children}
            <Footer />
        </main>
    </div>
);

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;