import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Post from "../components/post";


const TagsTemplate = ({pageContext, data}) => {

    const posts = data.getPosts.edges;
    const currentTag = pageContext.tag;

    return(
        <Layout>
            <SEO title={`Tags | #${currentTag}`} keywords={[currentTag]}/>
            <div className="blog">
                <div className="head">/tags/*</div>

                <div className="current-tag">{`#${currentTag}`}</div>

                <div className="posts">
                    {
                        posts.map(post => {
                            const { title, date, tags, slug } = post.node.frontmatter;

                            return (
                                <Post key={slug}
                                    title={title}
                                    date={date}
                                    tags={tags}
                                    slug={slug} />
                            )
                        })
                    }
                </div>
            </div>
        </Layout>
    );


}
export default TagsTemplate;

export const query = graphql`
query ($tag: String) {
    getPosts: allMarkdownRemark(limit: 2000, sort: {fields: [frontmatter___date], order: DESC}, filter: {frontmatter: {tags: {in: [$tag]}}}) {
        totalCount
        edges {
            node {
                frontmatter {
                    title
                    date
                    tags
                    slug
                }
            }
        }
    }
}
`;